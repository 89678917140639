/* body {
  white-space: pre-line;
} */

.content {
  margin: 0;
  padding: 0;
  padding-top: calc(64px + env(safe-area-inset-top));
  padding-bottom: 70px;
}

.paddedContent {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
}

hr {
  margin: 0;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
}

hr.light {
  width: 100%;
  margin: 0;
  border: 0.5px solid rgba(255, 255, 255, 0.5);
}

.smallTitle {
  font-size: 26px;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.smallTitle#center {
  text-align: center;
}

.hazard_list_title {
  font-weight: 900;
  font-size: 15px;
  line-height: 22px;
  padding: 0;
  margin: 0;
}

.hazard_list_text {
  font-size: 15px;
  line-height: 22px;
  padding: 0;
  margin: 0;
  margin-top: 10px;
}

.text {
  font-size: 15px;
  padding: 0;
  margin: 0;
}

.text2 {
  font-size: 15px;
  padding: 0;
  margin: 0;
  font-weight: bold;
}

button {
	transition: all 0.2s;
	width: 100%;
	min-height: 44px;
	border: none;
	background-color: #2061ae;
	border-radius: 4px;
	color: white;
	font-size: 17px;
	font-weight: 600;
	margin-top: 10px;
	cursor: pointer;
}

button:hover {
  background-color: #236cc3;
}

button:active {
  background-color: #1a58a1;
}

button:disabled {
  background-color: #cccdcf;
}

.outline {
  background-color: transparent;
  border: 1px solid #1a58a1;
  color: #1a58a1;
}

.outline:hover {
  background-color: rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.outline:active {
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.outline:disabled {
  background-color: #cccdcf;
}

.bottomButton {
  width: calc(100% - 40px);
  bottom: 70px; 
  position: absolute;
}

.loadingView {
  width: 60px;
  height: 60px;

  position: relative;
  margin: 100px auto;
}

input[type="text"] {
  width: 40%;
  border: none;
  color: #2061ae;
  font-size: 15px;
}

input[type="password"] {
  width: 40%;
  border: none;
  color: #2061ae;
  font-size: 15px;
}

select {
  width: 40%;
  border: none;
  color: #2061ae;
  font-size: 15px;
  margin-right: 6px;
}

textarea {
  width: 100%;
  border: none;
  color: #2061ae;
  font-size: 15px;
  height: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
}

img.fullWidth {
  width: 100%;
  max-width: 500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.launchLogo {
  width: 100%;
  height: 250px;
  background-image: url("./assets/LogoBox.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 44px;
}

.launchGradient {
  width: 100%;
  height: calc(100vh - 250px - 44px);
  background: linear-gradient(162.07deg, #2061ae 24.48%, #cedbeb 111.06%);
  position: absolute;
}

.launchTitle {
  text-align: center;
  margin-top: 100px;
  font-weight: bold;
  font-size: 15px;
  color: white;
}

table {
  margin-top: 20px;
}

th {
  background: #e0e8ef;
  padding: 7px 10px;
  text-align: left;
  color: #61494a;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
}

td {
  font-size: 13px;
  line-height: 18px;
  padding: 7px 10px;
  color: #61494a;
}

table tr:nth-child(odd) td {
  background: white;
}
table tr:nth-child(even) td {
  background: #f5f5f3;
}

.errorPopover {
  visibility: hidden;
  transition: visibility 0s, opacity 0.5s linear;
  box-sizing: border-box;
  width: calc(100% - 40px);
  padding: 20px;
  background-color: white;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  position: fixed;
  left: 20px;
  right: 20px;
  bottom: calc(84px - env(safe-area-inset-bottom));
  z-index: 100;
}

.errorPopover-text {
  color: color(srgb 0.721 0.12 0.12);
  padding: 0;
  margin: 0;
}

.successPopover {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: white;
  bottom: calc(84px - env(safe-area-inset-bottom));
  z-index: 100;
  padding-top: 100px;
}
