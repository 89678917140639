.modal-overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 4;
}

.modal {
	//animation: 0.3s ease-out 0s 1 slideInFromBottom;

	width: 100%;
	height: 100%;
	background-color: white;
	position: fixed;
	overflow-y: scroll;
}

@keyframes scale {
	0% {
		transform: scale(1.05);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
