.matrixGrid-helpBox {
	border: 5px solid gray;
	padding: 15px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.matrixGrid-helpBox-content {
	display: flex;
	flex-direction: row;
	box-sizing: border-box;
	justify-content: space-between;
}

.matrixGrid-helpBox#stateGreen {
	border-color: #97bf0d;
}

.matrixGrid-helpBox#stateYellow {
	border-color: #ffcc00;
}

.matrixGrid-helpBox#stateRed {
	border-color: #c10040;
}

.matrixGrid-noteBox {
	display: flex;
	gap: 20px;
	align-items: center;
	border-top: 1.5px solid rgba(0, 0, 0, 0.4);
	border-bottom: 1.5px solid rgba(0, 0, 0, 0.4);
	padding: 15px;
	margin-top: 20px;
}

.matrixGrid-noteBox-icon {
	width: 80px;
	height: 80px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.matrixGrid-noteBox-icon#allowed {
	background-image: url("../../../assets/ok.svg");
}

.matrixGrid-noteBox-icon#restricted {
	background-image: url("../../../assets/expl.svg");
}

.matrixGrid-noteBox-icon#notAllowed {
	background-image: url("../../../assets/error.svg");
}

.matrixGrid-helpBox-infoIcon {
	min-width: 22px;
	height: 22px;
	background-image: url("../../../assets/info_icon.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.matrixGrid-helpBox-infoIcon#light {
	filter: brightness(0) invert(1);
}

.matrixGrid-helpBox-arrowIcon {
	min-width: 22px;
	height: 22px;
	background-image: url("../../../assets/icon_arrow_down.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.matrixGrid-helpBox-arrowIcon#light {
	filter: brightness(0) invert(1);
}

.matrixGrid-scrollToTopButton {
	width: 17px;
	height: 22px;
	background-image: url("../../../assets/ScrollTop.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	margin-top: 40px;
	margin-left: auto;
	margin-right: auto;
}

span.textBold {
	font-weight: bold;
}
