.matrixGrid-item {
	box-sizing: border-box;
	background: white;
	border-radius: 8px;
	border: 0.5px solid #959697;
	color: black;
	aspect-ratio: 1/1;
	width: 100%;
	height: 100%;
}

.matrixGrid-item:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

/** Fallback (current, using intrinsic ratio technique) */
@supports not (aspect-ratio: 1 / 1) {
	.matrixGrid-item::before {
		float: left;
		padding-top: 100%;
		content: "";
	}

	.matrixGrid-item:after {
		display: block;
		content: "";
		clear: both;
	}
}

.matrixGrid-item#selected {
	background: #2061ae;
	border: 0.35px solid transparent;
}

.matrixGrid-item-content {
	height: calc(100% - 8px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;
	margin: 4px;
	border-radius: 4px;
	cursor: pointer;
}

.matrixGrid-item-content#default {
	background: #f5f5f3;
}

.matrixGrid-item#stateRed {
	background: #c10040;
	color: white;
}

.matrixGrid-item#stateRed:hover {
	background: #2061ae;
	color: white;
}

.matrixGrid-item-content#stateRed {
	background: #c10040;
	color: white;
}

.matrixGrid-item#stateYellow {
	background: #ffcc00;
	color: white;
}

.matrixGrid-item#stateYellow:hover {
	background: #2061ae;
	color: white;
}

.matrixGrid-item-content#stateYellow {
	background: #ffcc00;
	color: black;
}

.matrixGrid-item#stateGreen {
	background: #97bf0d;
	color: white;
}

.matrixGrid-item#stateGreen:hover {
	background: #2061ae;
	color: white;
}

.matrixGrid-item-content#stateGreen {
	background: #97bf0d;
	color: black;
}

p.matrixGrid-item-id {
	text-align: center;
	padding: 0;
	font-weight: bold;
	font-size: 13px;
	line-height: 18px;
	cursor: pointer;
	user-select: none;
	margin: 0;
	margin-bottom: 6px;
}

p.matrixGrid-item-warning {
	text-align: center;
	margin: 0;
	background-image: url("../assets/remarks_shape.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	width: 36px;
	height: 28px;
	color: black;
	line-height: 34px;
	font-style: normal;
	font-weight: bold;
	font-size: 10px;
}

.matrixGrid-item-warningsContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: 24px;
}
