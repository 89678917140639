.tabBar {
	width: 100%;
	height: 60px;
	background-color: #f9f9f9;
	position: fixed;
	bottom: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	border-top: 0.5px solid rgba(0, 0, 0, 0.3);
	margin: 0;
	padding: 0;
	padding-bottom: env(safe-area-inset-bottom);
}

.tabBarItem {
	min-width: 100px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 12px;
	align-items: center; /* center items horizontally */
	color: gray;
	cursor: pointer;
}

.tabBarItem#selected {
	color: #2061ae;
}

.tabBarItem-image {
	width: 28px;
	height: 28px;
	margin-bottom: 5px; /* gap for flex is not supported widely enough yet */
	margin-top: 5px; /* gap for flex is not supported widely enough yet */
	background-image: url("../assets/chevron_right.svg");
	background-size: 28px 28px;
	background-repeat: no-repeat;
}

.tabBarItem-image#info {
	background-image: url("../assets/Tab_Info.svg");
}

.tabBarItem-image#infoSelected {
	background-image: url("../assets/Tab_Info.svg");
	filter: invert(35%) sepia(12%) saturate(5247%) hue-rotate(183deg)
		brightness(66%) contrast(95%);
}

.tabBarItem-image#matrix {
	background-image: url("../assets/Tab_Matrix.svg");
}

.tabBarItem-image#matrixSelected {
	background-image: url("../assets/Tab_Matrix.svg");
	filter: invert(35%) sepia(12%) saturate(5247%) hue-rotate(183deg)
		brightness(66%) contrast(95%);
}

.tabBarItem-image#settings {
	background-image: url("../assets/Tab_Settings.svg");
}

.tabBarItem-image#settingsSelected {
	background-image: url("../assets/Tab_Settings.svg");
	filter: invert(35%) sepia(12%) saturate(5247%) hue-rotate(183deg)
		brightness(66%) contrast(95%);
}
