.tutorialOuterContent {
    background: linear-gradient(#ffffff, #9BB8D7);
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100vw;
}

.tutorialContent {
    position: absolute;
    top: 44px;
    /* for fixed top navigation */
    bottom: var(--tutorial-navigation-height);
    /* for bottom navigation */
    width: 100vw;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    flex: 1;
}

.tutorialInnerContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 80vw;
    max-width: 800px;
    align-self: center;
    height: 100%;
}

.tutorial {
    height: 100%;
    width: 100%;
    align-self: center;
}

.imageContainer {
    display: flex;
    flex-grow: 1;
    flex-basis: 50%;
    justify-content: center;
    padding-bottom: 10px;
}

.image {
    object-fit: contain;
    width: 100%;
    flex: 1;
}

.textContainer {
    display: flex;
    flex-direction: column;
    max-width: 80vw;
    font-size: 2vh;
    gap: 10px;
}

.title {
    font-weight: bold;
}

.introText {
    text-align: left;
    align-self: center;
}

.contentIntro {
    display: flex;
    padding: 40px 10px 10px 10px;
    gap: 10px;
    align-items: stretch;
    flex-direction: column;
    flex-basis: 50%;
}

.navigation {
    width: 100%;
    height: var(--tutorial-navigation-height);
    position: fixed;
    bottom: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
}

.buttonContainer {
    align-self: center;
    width: 80vw;
    max-width: 800px;
}

.swiper-pagination-bullet-active {
    background-color: #ffffff;
    width: 1rem;
    height: 1rem;
    border-radius: .5rem;
    border-color: black;
}

.swiper-pagination-bullet {
    background-color: black;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: .5rem;
    border-color: black;
}

:root {
    --swiper-theme-color: #000;
    --tutorial-navigation-height: 120px;
}

@media (max-width: 374px) {
    .image {
        object-fit: contain;
        height: auto;
    }
}

@media screen and (max-width: 1366px) and (orientation:landscape) {
    .contentIntro {
        display: flex;
        padding: 10px;
        gap: 10px;
        align-items: stretch;
        flex-direction: row;
        height: calc(100vh - 250px);
    }
    .textContainer {
        flex-grow: 1;
        flex-basis: 50%;
        align-self: center;
        justify-content: flex-start;
        max-width: 80vw;
        font-size: 1.5vw;
    }
}

@media screen and (min-width: 1366px) and (orientation:landscape) {
    .contentIntro {
        display: flex;
        padding: 10px;
        gap: 10px;
        align-items: stretch;
        flex-direction: row;
        height: calc(100vh - 250px);
    }
    .textContainer {
        flex-grow: 1;
        flex-basis: 50%;
        align-self: center;
        max-width: 80vw;
        font-size: 20px;
        /*fix font-size on wider screens */
    }
}