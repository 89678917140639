.tableViewHeader {
	min-height: 30px;
	padding-left: 20px;
	padding-right: 20px;
	margin-top: 30px;
	font-weight: 600;
	font-size: 15px;
	border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
}

.tableViewFooter {
	min-height: 30px;
	padding-left: 20px;
	padding-right: 20px;
	margin-top: 10px;
	font-weight: 400;
	font-size: 15px;
	color: gray;
}

.tableViewCell {
	transition: all 0.2s;
	width: calc(100% - 40px);
	min-height: 44px;
	border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center; /* center items horizontally */
	padding-left: 20px;
	padding-right: 20px;
	cursor: pointer;
	user-select: none;
	background-color: white;
}

.tableViewCell:hover {
	background-color: #dedede;
}

.tableViewCell#text:hover {
	background-color: white;
}

.tableViewCell-web {
	width: 16px;
	height: 16px;
	background-image: url("../assets/web.svg");
	background-size: contain;
	background-position: right;
	background-repeat: no-repeat;
	opacity: 0.4;
}
.tableViewCell-chevron {
	width: 18px;
	height: 12px;
	background-image: url("../assets/chevron_right.svg");
	background-size: contain;
	background-position: right;
	background-repeat: no-repeat;
}

.tableViewCell-checkmark {
	width: 24px;
	height: 16px;
	background-image: url("../assets/checkmark.svg");
	background-size: contain;
	background-position: right;
	background-repeat: no-repeat;
}

.tableViewCell-secureEntryToggle {
	width: 24px;
	height: 16px;
	background-image: url("../assets/secure_text_eye.svg");
	background-size: contain;
	background-position: right;
	background-repeat: no-repeat;
	position: absolute;
	right: 20px;
}

.tableViewCell-secureEntryToggle#on {
	background-image: url("../assets/secure_text_eye_on.svg");
}

.tableViewCell-right {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center; /* center items horizontally */
}

.tableViewCell-detail {
	margin: 0;
	padding: 0;
	color: gray;
}
