.modalBox-overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.4);
	top: 0;
	z-index: 2;
}

.modalBox {
	animation: 0.3s ease-out 0s 1 slideInFromBottom;

	width: calc(100% - 40px);
	background-color: white;
	position: fixed;
	bottom: 20px;
	left: 20px;
	border-radius: 14px;
}

.modalBox-title {
	margin: 0;
	padding: 15px;
	font-weight: bold;
}

.modalBox-content {
	padding: 15px;
}

.modalBox-text {
	margin: 0;
	margin-bottom: 10px;
}

@keyframes slideInFromBottom {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(0);
	}
}
