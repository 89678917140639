.matrixGrid {
	width: 100%;
	display: grid;
	grid-gap: 5px;
	grid-template-columns: repeat(5, 1fr);
	padding: 0;
	max-width: 600px;
	margin: auto;
}

@media only screen and (min-width: 380px) {
	.matrixGrid {
		grid-template-columns: repeat(5, 1fr);
	}
}

@media only screen and (min-width: 470px) {
	.matrixGrid {
		grid-template-columns: repeat(5, 1fr);
	}
}

.matrixGrid-wrapper {
	margin: 0;
	padding: 0;
	padding-top: 44px;
	padding-bottom: 70px;
	background: linear-gradient(
			153.53deg,
			rgba(155, 184, 215, 0.29) 14.86%,
			rgba(255, 255, 255, 0) 116.93%
		),
		#ffffff;
	min-height: calc(100vh - 170px);
}
