.matrixGrid-footer {
	font-size: 10px;
	color: gray;
	text-align: center;
	margin-top: 30px;
	cursor: pointer;
}

.matrixGrid-header {
	font-size: 14px;
	color: gray;
	text-align: center;
	margin-top: 0px;
	margin-bottom: 20px;
}

.matrixGrid-container {
	width: 100%;
	padding: 20px;
	padding-bottom: 80px;
	box-sizing: border-box;
}

.matrixGrid-container#center {
	height: calc(100vh - 170px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.matrixGrid-buttonBarResult {
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 10px;
	display: flex;
	flex-direction: row;
	box-sizing: border-box;
	position: fixed;
	left: 0;
	bottom: calc(80px + env(safe-area-inset-bottom));
}

.matrixGrid-buttonBar {
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 20px;
	padding-top: 10px;
	display: flex;
	flex-direction: row;
	box-sizing: border-box;
	background-color: white;
	position: fixed;
	left: 0;
	bottom: 60px;
	border-top: 0.5px solid rgba(0, 0, 0, 0.15);
}
