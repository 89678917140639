.tableItemCell {
  transition: all 0.2s;
  width: calc(100% - 40px);
  min-height: 44px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; /* center items horizontally */
  padding-left: 20px;
  padding-right: 20px;
  cursor: default;
  user-select: none;
  background-color: white;
}

.tableItemCell:hover {
  background-color: #dedede;
}

.tableItemCell-checkmark {
  width: 16px;
  height: 16px;
  background-image: url("src/assets/checkmark.svg");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  /* opacity: 0.4; */
}

.tableItemCell-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; /* center items horizontally */
}
